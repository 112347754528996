import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../src/environments/environment';
import { Router } from '@angular/router';
import { ConfigurationService } from './configuration.service';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';
import { UserRes } from '../models/user';
import { WebSocketNotificationService } from './websocket.service';
import { NotificationService } from './notification.service';
import { AssetsService } from './assets.service';

@Injectable({
    providedIn: 'root'
})
export class AppInitService {

    checkUserLoggedInInterval: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        private configurationService: ConfigurationService,
        private authService: AuthenticationService,
        private userService: UserService,
        private websocketNotificationService: WebSocketNotificationService,
        private notificationService: NotificationService,
        private assetsService: AssetsService
    ) {}

    startCheckUserLoggedInInterval() {
        this.checkUserLoggedInInterval = setInterval(() => {
            if (localStorage.getItem("token")) {
                this.authService.checkLogin(); 
            }
        }, parseInt(environment.configuration.checkUserLoggedInInterval))
    }

    getWebDeviceUUID() {
        let webDeviceUUID = localStorage.getItem("webDeviceUUID");
        if (webDeviceUUID) {
            return `${webDeviceUUID}`;
        } else {
            const newUUID = `${self.crypto.randomUUID()}`;
            localStorage.setItem("webDeviceUUID", newUUID);
            return newUUID;
        }
    }

    init(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            
            environment.webDeviceUUID = this.getWebDeviceUUID();
            
            this.configurationService.get().then((response) => {
                environment.configuration = response.configuration;

                if (!environment.configuration.enabledWebApp) {
                    this.router.navigate(['/maintenance']);
                }

                this.startCheckUserLoggedInInterval();
                
                if (this.authService.isLoggedIn()) {
                    this.userService.fetchUser().then((res) => {
                        environment.user = res.user;

                        this.websocketNotificationService.wsKey = environment.user.wschannel_key;
                        this.websocketNotificationService.initWebsocket();
                        environment.websocketNotificationService = this.websocketNotificationService;

                        this.notificationService.fetchNotifications();
                        environment.notificationService = this.notificationService;

                        this.userService.getCurrentUserFriends();
                        environment.userService = this.userService;

                        this.assetsService.fetchImageAssets().then((res) => {
                            // TODO GET REMOTE IMAGES INSTEAD OF STATIC HERE!
                            this.assetsService.initAssets(res.images);
                            resolve();
                        }).catch((err) => {
                            console.log("error on init application!", err);
                            environment.websocketNotificationService = null;
                            environment.notificationService = null;
                            environment.configuration = {
                                enabledWebApp: false
                            }
                            this.router.navigate(['/maintenance']);
                            resolve();
                        });
                    });
                } else {
                    environment.websocketNotificationService = null;
                    environment.notificationService = null;
                    resolve();
                }

                
            }).catch((err) => {
                console.log("error on init application!", err);
                environment.websocketNotificationService = null;
                environment.notificationService = null;
                environment.configuration = {
                    enabledWebApp: false
                }
                this.router.navigate(['/maintenance']);
                resolve();
            })
        });
    }
}
