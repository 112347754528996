import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import { AbstractApiService } from './base.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { PaginatedNotificationRes, NotificationRes, Notification } from '../models/notification';


@Injectable({
    providedIn: 'root'
})
export class NotificationService extends AbstractApiService {

    public lastNotificationsRes$ = new BehaviorSubject<PaginatedNotificationRes|null>(null);

    constructor(
        private router: Router,
        http: HttpClient
    ) {
        super(http);
        this.apiUrl = '';
    }

    async fetchNotifications(url?: string): Promise<PaginatedNotificationRes> {
        try {
            const realUrl = url ? url : `${this.getApiUrl()}user/notifications/`
            const res: any = this.http
                .get(realUrl, {
                    headers: this.getHeaders()
                })
            const notificationRes = await lastValueFrom(res) as PaginatedNotificationRes;
            if (!url) {
                // if first call update last notification received!
                this.lastNotificationsRes$.next(notificationRes);
            }
            return notificationRes;
        } catch (error) {
            throw error;
        }
    }

    async updateNotification(notificationID: number, entryData: any): Promise<NotificationRes> {
        try {
            let data = <any>{
                action: "update",
            }

            Object.keys(entryData).forEach((key) => {
                data[key] = entryData[key];
            })

            const res: any = this.http
                .post(`${this.getApiUrl()}user/notification/${notificationID}/`,
                    data,
                    {
                        headers: this.getHeaders()
                    })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

    async updateNotifications(action: string): Promise<NotificationRes> {
        try {
            let data = <any>{
                action: action,
            }

            const res: any = this.http
                .post(`${this.getApiUrl()}user/notifications/`,
                    data,
                    {
                        headers: this.getHeaders()
                    })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

    async deleteNotification(notificationId: number): Promise<any> {
        try {
            const res: any = this.http
                .delete(`${this.getApiUrl()}user/notification/${notificationId}/`,
                    {
                        headers: this.getHeaders()
                    })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }
}