const pjson = require('../../package.json');

export const environment = {
    production: true,
    version: pjson.version,
    server: {
        protocol: 'https',
        host: 'api.tuti.education',
        port: 443,
        apiPath: 'api/'
    },
    websocket: {
        host: 'api.tuti.education',
        port: 443
    },
    appTitle: "Tutì",
    configuration: <any>{},
    user: <any>{},
    swarmifyLoaded: false,
    websocketNotificationService: <any>Object,
    notificationService: <any>Object,
    userService: <any>Object,
    ImageAssets: <any>{},
    tt01DBVersion: 1,
    webDeviceUUID: ""
};