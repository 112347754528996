import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import { AbstractApiService } from './base.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ImageAssetRes, ImageAsset } from '../models/asset';
import { environment } from 'src/environments/environment';
import { NgxIndexedDBService } from 'ngx-indexed-db';


@Injectable({
    providedIn: 'root'
})
export class AssetsService extends AbstractApiService {

    constructor(
        private router: Router,
        http: HttpClient,
        private dbService: NgxIndexedDBService
    ) {
        super(http);
        this.apiUrl = '';
    }

    async fetchImageAssets(): Promise<ImageAssetRes> {
        try {
            const res: any = this.http
                .get(`${this.getApiUrl()}images/`, {
                    headers: this.getHeaders()
                })
            
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

    initAssets(remoteImages: any[]) {
        const stickers = remoteImages.filter((image) => image.type==="sticker");
        const stickerCustoms = remoteImages.filter((image) => image.type==="custom_sticker");
        const images = remoteImages.filter((image) => image.type==="image");
        const backgrounds = remoteImages.filter((image) => image.type==="background");

        environment.ImageAssets.image = [];
        environment.ImageAssets.background = [];
        environment.ImageAssets.sticker = [];
        environment.ImageAssets.custom_sticker = [];

        for (let i = 0; i < backgrounds.length; i++) {
            let imageAsset = {
                uuid: `${backgrounds[i]["uuid"]}`,
                name: `${backgrounds[i]["name"]}`,
                type: `${backgrounds[i]["type"]}`,
                title: backgrounds[i]["title"] ? `${backgrounds[i]["title"]}` : `${backgrounds[i]["name"]}`,
                link: `${backgrounds[i]["link"]}`,
                available: backgrounds[i]["available"],
                b64: undefined
            } as ImageAsset
            environment.ImageAssets.background.push(imageAsset)
        }

        for (let i = 0; i < images.length; i++) {
            let imageAsset = {
                uuid: `${images[i]["uuid"]}`,
                name: `${images[i]["name"]}`,
                type: `${images[i]["type"]}`,
                title: images[i]["title"] ? `${images[i]["title"]}` : `${images[i]["name"]}`,
                link: `${images[i]["link"]}`,
                available: images[i]["available"],
                b64: undefined
            } as ImageAsset
            environment.ImageAssets.image.push(imageAsset)
        }

        for (let i = 0; i < stickers.length; i++) {
            let imageAsset = {
                uuid: `${stickers[i]["uuid"]}`,
                name: `${stickers[i]["name"]}`,
                type: `${stickers[i]["type"]}`,
                title: stickers[i]["title"] ? `${stickers[i]["title"]}` : `${stickers[i]["name"]}`,
                link: `${stickers[i]["link"]}`,
                available: stickers[i]["available"],
                b64: undefined
            } as ImageAsset
            environment.ImageAssets.sticker.push(imageAsset)
        }

        for (let i = 0; i < stickerCustoms.length; i++) {
            let imageAsset = {
                uuid: `${stickerCustoms[i]["uuid"]}`,
                name: `${stickerCustoms[i]["name"]}`,
                type: `${stickerCustoms[i]["type"]}`,
                title: stickerCustoms[i]["title"] ? `${stickerCustoms[i]["title"]}` : `${stickerCustoms[i]["name"]}`,
                link: `${stickerCustoms[i]["link"]}`,
                available: stickerCustoms[i]["available"],
                b64: undefined
            } as ImageAsset
            environment.ImageAssets.custom_sticker.push(imageAsset)
        }

        setTimeout(() => {
            // TODO: IndexDB local save/load imageAssets
            //this.preloadImageAssets();
        }, 2000);
        
    }

    preloadImageAssets() {
        /*
        * Add here all images to be preloaded;
        */
        let c = 1;
        const all_image_assets = [
            ...environment.ImageAssets.background,
            ...environment.ImageAssets.image,
            ...environment.ImageAssets.custom_sticker,
            ...environment.ImageAssets.sticker
        ]
        all_image_assets.forEach((i: any) => {
            setTimeout(() => {
                let img = new Image();
                img.src = i.link;
            }, c * 500);
            c++;
        });
    }
}