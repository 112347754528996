import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import { AbstractApiService } from './base.service';
import { lastValueFrom } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class ConfigurationService extends AbstractApiService {

    constructor(
        private router: Router,
        http: HttpClient
    ) {
        super(http);
        this.apiUrl = '';
    }

    async get(): Promise<any> {
        try {
            const res: any = this.http
                .get(`${this.getApiUrl()}configuration/`, {
                    headers: this.getBaseHeaders()
                })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

}
