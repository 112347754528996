import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AbstractApiService } from './base.service';
import { environment } from 'src/environments/environment';
import { UserRegistration } from '../models/user';
import { lastValueFrom } from 'rxjs';
import { WebSocketNotificationService } from './websocket.service';
import { NotificationService } from './notification.service';
import { AssetsService } from './assets.service';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends AbstractApiService {

    private isLoggedInChecked = false;

    constructor(
        private router: Router,
        http: HttpClient,
        private websocketNotificationService: WebSocketNotificationService,
        private notificationService: NotificationService,
        private userService: UserService,
        private assetsService: AssetsService
    ) {
        super(http);
        this.apiUrl = 'login/';
    }

    logout(byInterceptor: boolean = false) {
        if (byInterceptor) {
            localStorage.removeItem('token');
            environment.user = null;
            environment.websocketNotificationService = null;
            environment.notificationService = null;
            environment.userService = null;
            this.router.navigate(['/login'], {
                "queryParams": {
                    "reason": "sessionExpired"
                }
            }).then(() => {
                // fixes strange bug not displaying form fields for login
                // after some navigation
                window.location.reload();
            })
        } else {
            this.http.post(`${this.getBaseUrl()}/api/logout/`, {}, {
                headers: this.getHeaders()
            }).toPromise().then().catch();
            localStorage.removeItem('token');
            environment.user = null;
            environment.websocketNotificationService = null;
            environment.notificationService = null;
            environment.userService = null;
            this.router.navigate(['/login']).then(() => {
                // fixes strange bug not displaying form fields for login
                // after some navigation
                window.location.reload();
            })
        }

    }

    login(user: any, disableLogEvent: boolean = false) {
        return this.http
            .post(`${this.getApiUrl()}`, {
                username: user.username,
                password: user.password,
                disableLogEvent: disableLogEvent
            },{headers: this.getBaseHeaders()})
            .toPromise()
            .then((response: any) => {
                if (response.success && !response.user.is_staff && !response.user.is_superuser) {
                    localStorage.setItem('token', response.token);
                    environment.user = response.user;

                    // init ws (works only with reload on logout bug fix implemented otherwise unsubcribe ws for avoid duplicates!!!!)
                    this.websocketNotificationService.wsKey = environment.user.wschannel_key;
                    this.websocketNotificationService.initWebsocket();
                    environment.websocketNotificationService = this.websocketNotificationService;

                    // init notifications (works only with reload on logout bug fix implemented otherwise unsubcribe notification for avoid duplicates!!!!)
                    this.notificationService.fetchNotifications();
                    environment.notificationService = this.notificationService;

                    // init notifications (works only with reload on logout bug fix implemented otherwise unsubcribe notification for avoid duplicates!!!!)
                    this.userService.getCurrentUserFriends();
                    environment.userService = this.userService;

                    this.assetsService.fetchImageAssets().then((res) => {
                        // TODO GET REMOTE IMAGES INSTEAD OF STATIC HERE!
                        this.assetsService.initAssets(res.images);
                    });

                    return response;
                }
                throw 'invalid credentials!';
            })
            .catch(err => {
                return err;
            });
    }

    checkLogin() {
        return this.http
            .get(`${this.getApiUrl()}check-user-is-logged/`, {
                headers: this.getHeaders()
            })
            .toPromise()
            .then((response: any) => { })
            .catch(err => {
                return err;
            });
    }

    isLoggedIn() {
        return localStorage.getItem('token') ? true : false;
    }

    async signup(signupData: UserRegistration) {
        const signup = this.http
            .post(`${this.getBaseUrl()}/api/registration/`,
                {
                    username: signupData.username,
                    school_year: signupData.school_year,
                    password: signupData.password,
                    email: signupData.email.trim(),
                    type: signupData.type,
                    parent_email: signupData.parent_email?.trim(),
                    language: signupData.language,
                },
                {
                    headers: this.getBaseHeaders()
                });

        return await lastValueFrom(signup)
            .then((response: any) => {
                return response;
            });
    }

    async confirmAccount(activation_key: string) {
        const confirmAccount = this.http.get(`${this.getBaseUrl()}/api/registration?activation_key=${activation_key}`);

        return await lastValueFrom(confirmAccount)
            .then((response: any) => {
                return response;
            });
    }

    async requestPassword(email: string) {
        const forgotPassword = this.http
            .post(`${this.getBaseUrl()}/api/user/request-password/`,
                {
                    email: email
                },
                {
                    headers: this.getBaseHeaders()
                });

        return await lastValueFrom(forgotPassword)
            .then((response: any) => {
                return response;
            });
    }

    async passwordReset(email: string, resetpassword_key: string, password: string) {
        const passwordReset = this.http
            .post(`${this.getBaseUrl()}/api/user/reset-password/`,
                {
                    email: email,
                    resetpassword_key: resetpassword_key,
                    password: password
                },
                {
                    headers: this.getBaseHeaders()
                });

        return await lastValueFrom(passwordReset)
            .then((response: any) => {
                return response;
            });
    }

}

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authenticationService.isLoggedIn()) {
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
        return true;
    }
}

@Injectable({
    providedIn: 'root'
})
export class NotLoggedInGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authenticationService.isLoggedIn()) {
            return true;
        }
        this.router.navigate(['home']);
        return false;
    }
}


@Injectable({
    providedIn: 'root'
})
export class UserTypeGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.isLoggedIn() && environment.user?.type === "student") {
            return true;
        }
        this.router.navigate(['home']);
        return false;
    }
}
