import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ExternalUrlRedirectResolver implements Resolve<any> {
    constructor() { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let externalUrl = route.data['externalUrl']();
        window.location.assign(externalUrl);
        return null;
    }
}