import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import { AbstractApiService } from './base.service';
import { AuthenticationService } from './authentication.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { UserRes, User, UserFriendsRes, UserFriendshipRequestsRes, SearchUsersRes } from '../models/user';
import { UserRoomsRes } from '../models/rooms';


@Injectable({
    providedIn: 'root'
})
export class UserService extends AbstractApiService {

    public lastFriendshipRes$ = new BehaviorSubject<UserFriendsRes|null>(null);

    constructor(
        private router: Router,
        http: HttpClient
    ) {
        super(http);
        this.apiUrl = '';
    }

    async fetchUser(): Promise<UserRes> {
        try {
            const res: any = this.http
                .get(`${this.getApiUrl()}user/`, {
                    headers: this.getHeaders()
                })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

    async updateUser(entryData: any): Promise<UserRes> {
        try {
            let data = <any>{
                action: "update",
            }

            Object.keys(entryData).forEach((key) => {
                data[key] = entryData[key];
            })

            const res: any = this.http
                .post(`${this.getApiUrl()}user/`,
                    data,
                    {
                        headers: this.getHeaders()
                    })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

    async getCurrentUserFriends(url?: string): Promise<UserFriendsRes> {
        try {
            const realUrl = url ? url : `${this.getApiUrl()}user/friends/`
            const res: any = this.http
                .get(realUrl, {
                    headers: this.getHeaders()
                })
            const friendRes = await lastValueFrom(res) as UserFriendsRes;
            if (!url) {
                // if first call update last notification received!
                this.lastFriendshipRes$.next(friendRes);
            }
            return friendRes;
        } catch (error) {
            throw error;
        }
    }

    async getCurrentUserFriendshipRequests(): Promise<UserFriendshipRequestsRes> {
        try {
            const res: any = this.http
                .get(`${this.getApiUrl()}user/friendship-requests/`, {
                    headers: this.getHeaders()
                })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

    async searchUserFriendsByText(page: number, searchText: string): Promise<UserFriendsRes> {
        try {
            const res: any = this.http
                .get(`${this.getApiUrl()}user/friends/?page=${page}&search_text=${searchText}`, {
                    headers: this.getHeaders()
                })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

    async searchUserFriendByUUID(uuid: string): Promise<UserFriendsRes> {
        try {
            const res: any = this.http
                .get(`${this.getApiUrl()}user/friends/?uuid=${uuid}`, {
                    headers: this.getHeaders()
                })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

    async sendFriendRequest(uuid: string, action: string): Promise<UserRes> {
        try {
            const res: any = this.http
                .post(`${this.getApiUrl()}publicuser/${uuid}/`,
                    {
                        action: action
                    },
                    {
                        headers: this.getHeaders()
                    })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

    async acceptFriendRequest(uuid: string, action: string): Promise<UserRes> {
        try {
            const res: any = this.http
                .post(`${this.getApiUrl()}user/friendship-request/${uuid}/`,
                    {
                        action: action
                    },
                    {
                        headers: this.getHeaders()
                    })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

    async searchUsersByText(searchText: string): Promise<SearchUsersRes> {
        try {
            const res: any = this.http
                .get(`${this.getApiUrl()}user/users-search/?search_text=${searchText}`, {
                    headers: this.getHeaders()
                })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }

    async getCurrentUserRooms(): Promise<UserRoomsRes> {
        try {
            const res: any = this.http
                .get(`${this.getApiUrl()}user/rooms/`, {
                    headers: this.getHeaders()
                })
            const roomRes = await lastValueFrom(res) as UserRoomsRes;
            return roomRes;
        } catch (error) {
            throw error;
        }
    }

    async contactTeacher(teacherUUID: string, textForTeacher: string, name: string, surname: string): Promise<UserRes> {
        try {
            const res: any = this.http
                .post(`${this.getApiUrl()}publicuser/${teacherUUID}/`,
                    {
                        action: "contact-teacher",
                        text: textForTeacher,
                        name: name,
                        surname: surname
                    },
                    {
                        headers: this.getHeaders()
                    })
            return await lastValueFrom(res);
        } catch (error) {
            throw error;
        }
    }
}
